import { analytics } from "@/firebase/firebaseConfig";
import { logEvent } from "firebase/analytics";
import { useLogAnalyticsEventClient } from "@/firebase/useLogAnalyticsEventClient";
export type AnalyticsEventType = "view-login" | "view-signup" | "view-templates" | "view-video" | "view-video-embed" | "view-video-library" | "view-video-player-style" | "view-video-create" | "view-video-demo" | "view-video-upload" | "view-password-change" | "login" | "logout" | "signup" | "password-changed" | "create-video-from-link" | "upload-video" | "delete-video" | "share-video";

/**
 * Log an analytics event to Firebase Analytics
 * If Firebase Analytics is not supported, log the event to the console
 **/
export const logAnalyticsEvent = async (event: AnalyticsEventType, eventParams?: Object) => {
  const analyticsInstance = await analytics;
  if (analyticsInstance) {
    logEvent(analyticsInstance, event as string, {
      platform: "web",
      ...eventParams
    });
  } else {
    console.log("Event: '" + event + "'", eventParams ? "with params:" : "", eventParams ? eventParams : "");
  }
};

/**
 * Wrapper for the useLogAnalyticsEventClient hook so that it doesn't die when run on the server side
 **/
export const useLogAnalyticsEvent = typeof window === 'undefined' ? logAnalyticsEvent : useLogAnalyticsEventClient;