import {functions, httpsCallable} from "@/firebase/firebaseConfig";
import {Subscription} from "@/lib/types";

export function retrieveSubscription(uid: string): Promise<Subscription> {
    const retrieveSubscription = httpsCallable(functions, 'subscription-retrieve')
    return retrieveSubscription({uid}).then(result => {
        return result.data as Subscription
    }).catch(error => {
        console.error('Error retrieving subscription', error)
        throw new Error('Error retrieving subscription')
    })
}

export function createCustomerSession(uid: string): Promise<string> {
    const createCustomerSession = httpsCallable(functions, 'billing-createCustomerSession')
    return createCustomerSession({uid}).then(result => {
        return (result.data as {clientSecret: string}).clientSecret
    }).catch(error => {
        console.error('Error creating customer session', error)
        throw new Error('Error creating customer session')
    })
}

export function hostedVideoCalculation(subscription: Subscription) {
    if (subscription.type === null) {
        return `${Math.ceil(subscription.hostedVideoUsage)} / 15mins`
    } else if (subscription.type === 'Supporter') {
        return `${Math.ceil(subscription.hostedVideoUsage)} / 240mins`
    } else if (subscription.type === 'Believer') {
        return `${Math.ceil(subscription.hostedVideoUsage)} / 6000mins`
    } else {
        return 'N/A'
    }
}

export function hostedVideoCalculationInPercentage(subscription: Subscription) {
    if (subscription.type === null) {
        return Math.ceil((subscription.hostedVideoUsage / 15) * 100);
    } else if (subscription.type === 'Supporter') {
        return Math.ceil((subscription.hostedVideoUsage / 240) * 100);
    } else if (subscription.type === 'Believer') {
        return Math.ceil((subscription.hostedVideoUsage / 6000) * 100);
    }
}
