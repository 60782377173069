'use client';

import React, { useEffect } from "react";
import "react-bootstrap";
const Bootstrap = function ({
  children
}: {
  children?: React.ReactNode;
}) {
  useEffect(() => {
    // @ts-ignore
    import('bootstrap/dist/js/bootstrap');
    console.log('Loading Bootstrap JS');
  }, []);
  return null;
};
export function LayoutJSIncludes({
  children
}: {
  children?: React.ReactNode;
}): React.ReactElement {
  return <>
        <>
            <Bootstrap data-sentry-element="Bootstrap" data-sentry-source-file="LayoutJSIncludes.tsx" />
            {children}
        </>
    </>;
}