import {UserPermissions} from "@/lib/types";
import {currentUser} from "@/firebase/firebaseAuth";
import {doc, DocumentSnapshot, getDoc} from "firebase/firestore";
import {firestore} from "@/firebase/firebaseConfig";

export function getUserPermissions(): Promise<UserPermissions | null> {
    const user = currentUser()

    if (!user) {
        return Promise.resolve(null)
    }

    return getDoc(doc(firestore, 'UserPermissions', user.uid))
        .then(snapshot => {
            return mapUserPermissions(snapshot)
        })
}

function mapUserPermissions(docSnap: DocumentSnapshot): UserPermissions {
    if (!docSnap.exists()) {
        return {
            admin: false,
            dubbing: false,
            dateCreated: new Date(),
            lastUpdated: new Date()
        }
    }

    const data = docSnap.data()
    return {
        ...data,
        admin: data?.admin,
        dubbing: data?.dubbing,
        dateCreated: data.dateCreated ? data.dateCreated.toDate() : new Date(),
        lastUpdated: data.lastUpdated ? data.lastUpdated.toDate() : new Date(),
    }
}
