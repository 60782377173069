import {functions, firestore, httpsCallable} from "@/firebase/firebaseConfig";
import {
    query, where, getDocs, doc, getDoc,
    collection,
    runTransaction,
    increment,
    serverTimestamp, setDoc,
    updateDoc,
} from 'firebase/firestore';
import {BillingAccount, BillingAccountUsers, BillingTransactionParams, BillingTransactions} from '@/lib/types'; // Adjust the path as necessary


export function getTotalCredits(): Promise<number> {
    const getTotalCredits = httpsCallable(functions, 'billing-getTotalCredits');
    return getTotalCredits().then((result) => {
        return (result.data as { credits: number }).credits
    }).catch((error) => {
        console.error('Error getting total credits', error)
        throw new Error('Error getting total credits')
    })
}

export function convertCreditsToMins(credits: number): number {
    // 1 credit = 1 second
    return Math.ceil(credits / 60)
}

// export async function getTotalCredits(uid: string): Promise<number> {
//     const docQuery = query(collection(firestore, 'BillingAccountUsers'), where("uid", "==", uid))
//     const querySnapshot = await getDocs(docQuery)
//     if (!querySnapshot.empty) {
//         const billingAccountId = querySnapshot.docs[0].data().billingAccountId
//         const billingAccountRef = doc(firestore, 'BillingAccounts', billingAccountId)
//         const billingAccountDoc = await getDoc(billingAccountRef)
//         if (billingAccountDoc.exists()) {
//             return billingAccountDoc.data().balance
//         } else {
//             throw new Error("Billing account not found")
//         }
//     } else {
//         throw new Error("Billing account user not found")
//     }
// }


/**
 * Fetches the billing accounts associated with a given user ID.
 * @param uid - The user ID to fetch billing accounts for.
 * @returns A promise that resolves to a list of BillingAccount objects.
 */
export const getBillingAccountsForUser = async (uid: string): Promise<BillingAccount[]> => {
    try {
        // Reference to the BillingAccountUsers collection
        const billingAccountUsersRef = collection(firestore, 'BillingAccountUsers');

        // Query to find billing account associations for the user
        const userBillingQuery = query(billingAccountUsersRef, where('uid', '==', uid));
        const userBillingSnapshot = await getDocs(userBillingQuery);

        // Get list of billingAccountIds associated with the user
        const billingAccountIds = userBillingSnapshot.docs.map(doc => doc.data().billingAccountId);

        if (billingAccountIds.length === 0) {
            return []; // Return an empty array if no billing accounts are found
        }

        // Reference to the BillingAccounts collection
        const billingAccountsRef = collection(firestore, 'BillingAccounts');

        // Fetch billing account details for each billingAccountId using document references
        const billingAccountPromises = billingAccountIds.map(async (billingAccountId) => {
            const accountDocRef = doc(billingAccountsRef, billingAccountId);
            const accountSnapshot = await getDoc(accountDocRef);

            if (accountSnapshot.exists()) {
                return {
                    id: accountSnapshot.id,
                    ...accountSnapshot.data()
                } as BillingAccount;
            }
        });

        // Wait for all billing account fetch promises to resolve
        const billingAccounts = await Promise.all(billingAccountPromises);

        // Filter out any undefined values that may have occurred if a document was not found
        return billingAccounts.filter(account => account !== undefined) as BillingAccount[];
    } catch (error) {
        console.error('Error fetching billing accounts:', error);
        throw error;
    }
};


/**
 * Used by the admin UI to create a new billing transaction on an ad-hoc basis
 * @param params
 */
export async function createBillingTransaction(params: BillingTransactionParams): Promise<BillingTransactions> {
    try {
        // Create a new Firestore database transaction
        return await runTransaction(firestore, async (transaction) => {
            // Create the BillingTransactions record
            const billingLedgerRef = doc(collection(firestore, 'BillingTransactions'));
            const billingLedgerData = {
                billingAccountId: params.billingAccountId,
                value: Math.ceil(params.value), // Rounding off the value
                description: params.description,
                dateCreated: serverTimestamp(),
            };
            transaction.set(billingLedgerRef, billingLedgerData);

            // Update the BillingAccount record with the new balance
            const billingAccountRef = doc(firestore, 'BillingAccounts', params.billingAccountId);
            transaction.update(billingAccountRef, {
                balance: increment(params.value),
                lastUpdated: serverTimestamp(),
            });

            // Return the BillingLedger record
            return {
                ...billingLedgerData,
                id: billingLedgerRef.id,
                dateCreated: new Date(),
            } as BillingTransactions;
        });
    } catch (error) {
        console.error("Error in creating billing transaction", error);
        throw new Error("Error in creating billing transaction");
    }
}
