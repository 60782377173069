import { Col, Container, Row, Spinner } from "react-bootstrap";
import React from "react";
import { useRouter } from "next/navigation";
import { useAuthContext } from "@/context/AuthContext";
export default function Home() {
  const {
    user,
    loading
  } = useAuthContext();
  const router = useRouter();
  React.useEffect(() => {
    if (user === null) {
      router.push("/login");
    } else if (user) {
      router.push("/video");
    }
  }, [user, router]);
  return <>
            <Container className="rounded mx-auto d-block" data-sentry-element="Container" data-sentry-source-file="index.tsx">
                <Row className=" align-items-center justify-content-center no-gutters min-dvh-100" data-sentry-element="Row" data-sentry-source-file="index.tsx">
                    <Col className={"d-flex align-items-center justify-content-center"} data-sentry-element="Col" data-sentry-source-file="index.tsx">
                        <Spinner animation="border" className={"text-primary"} role="status" data-sentry-element="Spinner" data-sentry-source-file="index.tsx">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </Col>
                </Row>
            </Container>
        </>;
}