'use client';

import { useEffect } from "react";
import { AnalyticsEventType, logAnalyticsEvent } from "@/firebase/firebaseAnalytics";

/**
 * Custom hook to log analytics events
 * This hook is useful when run in side the body of a component
 * so that the event is only logged when the component is mounted
 * and not re-run on every render.
 *
 * This is primarily used for logging page views and other events that should only be logged once.
 *
 * Placed in its own file because Next.js has a fit if this isn't marked as Client Side only
 **/
export function useLogAnalyticsEventClient(event: AnalyticsEventType, eventParams?: Object) {
  useEffect(() => {
    logAnalyticsEvent(event, eventParams);
  }, []);
  return null;
}