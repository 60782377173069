import '@/styles/globals.css';
import type { AppProps } from 'next/app';

// add bootstrap css
import '@/styles/bootstrap.scss';
// add player css
import '@/styles/player.css';
import '@/styles/globals.css';
import { AuthContextProvider } from "@/context/AuthContext";
import { ThemeProvider } from "react-bootstrap";
import { LayoutJSIncludes } from "@/layouts/LayoutJSIncludes";
import React, { useEffect, useRef } from "react";
import { Router, useRouter } from 'next/router';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
const POSTHOG_KEY = process.env.NEXT_PUBLIC_POSTHOG_KEY || "";
const POSTHOG_HOST = process.env.NEXT_PUBLIC_POSTHOG_HOST || "https://us.i.posthog.com";
export default function App({
  Component,
  pageProps
}: AppProps) {
  const router = useRouter();
  const oldUrlRef = useRef('');

  // PostHog configuration
  useEffect(() => {
    posthog.init(POSTHOG_KEY, {
      api_host: POSTHOG_HOST,
      // Enable debug mode in development
      loaded: posthog => {
        if (process.env.NODE_ENV === 'development') posthog.debug();
      }
    });
    const handleRouteChange = () => posthog?.capture('$pageview');
    const handleRouteChangeStart = () => posthog?.capture('$pageleave', {
      $current_url: oldUrlRef.current
    });
    Router.events.on('routeChangeComplete', handleRouteChange);
    Router.events.on('routeChangeStart', handleRouteChangeStart);
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
      Router.events.off('routeChangeStart', handleRouteChangeStart);
    };
  }, []);
  return <>
            <AuthContextProvider data-sentry-element="AuthContextProvider" data-sentry-source-file="_app.tsx">
                <ThemeProvider breakpoints={['jumbo', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs']} minBreakpoint="xs" data-sentry-element="ThemeProvider" data-sentry-source-file="_app.tsx">
                    <PostHogProvider client={posthog} data-sentry-element="PostHogProvider" data-sentry-source-file="_app.tsx">
                        <Component {...pageProps} data-sentry-element="Component" data-sentry-source-file="_app.tsx" />
                    </PostHogProvider>
                </ThemeProvider>
            </AuthContextProvider>
            <LayoutJSIncludes data-sentry-element="LayoutJSIncludes" data-sentry-source-file="_app.tsx" />
        </>;
}